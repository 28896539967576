#filter-card {
    padding: 8px;
    position: relative;
}

.filter-add {
    padding: 8px;
    border-radius: 15px;
    padding-left: 16px;
    padding-right: 8px;
    padding-bottom: 16px;
}

.btn-top-right {
    position: absolute;
    top: 10px;
    right: 0px;
}

.component {
    overflow: auto;
}

.filter-card-prop {
    font-weight: 500;
    text-align: left;
}

.filter-card-prop:hover {
    cursor: pointer;
}

.selected-card {
    border:2px solid #e32359;
    border-radius: 6px;
    background-color: #f2e6ea;
}

.unselected-card:hover {
    background-color: rgb(250, 250, 250);
}

.pad-sides {
    padding-right: 10px;
    display: flex;
    align-items: stretch;
}

.filter-card-btn {
    cursor: pointer;
    border-style: solid;
    border-width: 1.5px;
    /* border-color: rgb(148, 127, 127); */
    border-color: #8f8f8f;
    border-radius: 6px;
    padding-right: 6px;
    font-size: small;
    font-weight: 500;
    background-color: white;
    display: flex;
}

.filter-card-btn:hover {
    /* color:#e32359; */
    /* border-color: #e32359; */
    border:1.5px solid #e32359;
}

.pad-top {
    padding-top: 10px;
}

.pad-top-small {
    padding-top: 7px;
}