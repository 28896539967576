.animation-settings {
    margin-left: 50px;
}

.animation-settings.collapsed {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
}

.animation-settings.expanded {
    max-height: 700px;
    transition: max-height 0.5s ease;
}

.bounce-settings {
    margin-left: 30px;
    margin-bottom: 15px;
}

.sway-settings {
    margin-left: 30px;
    margin-bottom: 15px;
}

.faded-text {
    opacity: 0.4;
}

.bold-text {
    font-style:italic;
    color: rgb(225 49 90);
}

div.arrow {
    font-weight: bold;
    font-size: xx-large;
    color: rgb(225 49 90);
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.local-florist-icon {
    width: 35px;
    height: 35px;
}


.l-ink-name-label {
    margin-right: 8px;
}

.lsystem-axiom {
    margin-bottom: 25px;
}

.lsystem-param {
    display: block;
    margin-bottom: 5px;
    min-height: 6em;
    border-bottom: 1px solid rgb(216, 216, 216);
    padding-bottom: 10px;
    padding-top: 15px;
}

.short-input-box {
    width: 1em;
}

.display-block {
    display: block;
}

.l-ink-input {
    padding: 5px;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    border-bottom: 1.2px solid rgb(148, 148, 148);
    background: transparent;
    transition: border-color 0.3s;
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.3em;
    text-align: center;
    outline: none;
}

/* .l-ink-input:hover {
    border-bottom-color: rgb(39, 45, 77);
    border-bottom: 2px solid;
}

.l-ink-input:focus {
    border-bottom-color: rgb(39, 45, 77);
    border-bottom: 2px solid;
    outline: none;
} */

.medium-input-box {
    width: 8em;
}
  
.axiom-box-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.rule-box-container {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-around;
    min-width: 350px;
}

.rule-title {
    font-weight: 450;
    margin-bottom: 6px;
}

.l-ink-vertical-setting-spacer {
    padding-left: 1em;
    margin-right: 2em;
    border-right: 1px solid darkgrey;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.l-ink-vertical-setting-spacer.collapsed {
    height: 40em;
    transition: height 0.5s;
}

.l-ink-vertical-setting-spacer.expanded {
    height: 50em;
    transition: height 0.5s;
    transition-delay: 100ms;
}

.rounded-svg-box {
    border-radius: 6px;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgba(27, 31, 35, 0.15);
}

#l-ink-preview-box-with-settings {
    margin-top: 50px;
    margin-left: 30px;
    position: absolute;
}

#l-ink-preview-box-placeholder {
    margin-top: 10px;
    margin-right: 50px;
    margin-left: 30px;
    flex: 1;
    width: 250px;
    height: 100px;
}

#l-ink-settings-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

#l-ink-settings-box span {
    margin-top: 27px;
}

.rule-visual-and-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rule-instructions {
    font-size: 1.3em;
    width: 400px;
    margin-bottom: 5px;
}

.bold-pink {
    font-weight: bold;
    color: rgb(225 49 90);
}