.enable-depth-btn {
    appearance: none;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
    text-align: center;
    font-weight: 500;
    color:rgb(100, 100, 100)
}

.enable-depth-btn:hover {
    border: 1px solid rgb(157, 157, 157);
    text-decoration: none;
    transition-duration: 0.1s;
}
  
.enable-depth-btn:active {
    background-color: #EDEFF2;
    transition: none 0s;
}