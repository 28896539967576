#inkpage {
    height: 100%;
    width: 100%;
    background-color: rgb(240, 238, 238);
    color: white;
    flex: 2;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 999;
}

#ink-topbar {
    text-align: right;
    background-color: rgb(250,250,250);
    color: black;
    display: block;
    padding: 10px;
}

#ink-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

@media only screen and (max-width: 1200px) {
    #ink-outer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }  
} 

@media only screen and (min-width: 1200px) {
    #ink-outer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }    
} 

#add-ink-btn{
    margin-left: 25px;
}